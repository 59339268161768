export default function Mission() {
    return (
        <>
        <div className="mission_box">
            <div className="mission_textbox">
                <div className="mission_text">Разработка <strong>web-приложений</strong> на заказ.</div>
            </div>
            <div className="mission_company">Митра Про 123</div>

        </div>
        </>
    );
}

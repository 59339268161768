import { useState } from "react";

interface TelNumProps {
    telNum: string;
    telNumHiden: string;
    className: string;
}
export default function TelephoneNumberHiden(props:TelNumProps): JSX.Element {
    const [showTelNumber, SetShowTelNumber] = useState(false);
    
    return (
        <>
            <div className={props.className} onClick={() => SetShowTelNumber(!showTelNumber)}>
                {(showTelNumber) ? props.telNum : props.telNumHiden}
            </div>
        </>
    );
}
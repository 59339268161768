import { Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import ServicesPage from './ServicesPage';
import TechnologiesPage from './TechnologiesPage';
import ProjectsPage from './ProjectsPage';
import ContactsPage from './ContactsPage';

export default function Content() {
    const Page404 = () => {
        return (
          <>
            <h1>Page404</h1>
          </>);
        }

    return (
        <>
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/technologies" element={<TechnologiesPage />} />
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/contacts" element={<ContactsPage />} />
            <Route path='*' element={<Page404 />} />
        </Routes>
        </>
    );
}
import ContentHeader from "./ContentHeader";
import DevStageItem from "./DevStageItem";

export default function DevStages() {
    return (
        <>
        <ContentHeader textHearder={'Этапы разработки'} />
        <DevStageItem namberPoint={1} namePoint="Анализ задачи" />
        <DevStageItem namberPoint={2} namePoint="Техническое задание" />
        <DevStageItem namberPoint={3} namePoint="Дизайн" />
        <DevStageItem namberPoint={4} namePoint="Программирование" />
        <DevStageItem namberPoint={5} namePoint="Тестирование" />
        <DevStageItem namberPoint={6} namePoint="Запуск" />
        </>
    );
}

type textHearder = {
    textHearder: string
}

export default function ContentHeader(props:textHearder) {
    return (
        <>
        <div className="content_header">
            <b className="content_header_text">{props.textHearder}</b>
        </div>
        </>
    );
}
type stageItem = {
    namberPoint: number;
    namePoint: string;
}

export default function DevServiceItem(props:stageItem) {
    return (
        <>
        <div className="stage_item">
            <div className="stage_item_number">
                {props.namberPoint}
            </div>
            <div className="stage_item_text">
                {props.namePoint}
            </div>
        </div>
        </>
    );
}
import ContentHeader from "./ContentHeader";
import TechnologiesItem from "./TechnologiesItem";


export default function TechnologiesPage() {
    return (
        <>
        <div className="content_box">
            <ContentHeader textHearder={'Технологии'} />
            <TechnologiesItem imgUrl="logo-HTML-5-96.png" />
            <TechnologiesItem imgUrl="logo-C2plus.png" />
            <TechnologiesItem imgUrl="logo-css3-96.png" />
            <TechnologiesItem imgUrl="logo-django-96.png" />
            <TechnologiesItem imgUrl="logo-docker-96.png" />
            <TechnologiesItem imgUrl="logo-git-96.png" />
            <TechnologiesItem imgUrl="logo-javascript-96.png" />
            <TechnologiesItem imgUrl="logo-python-96.png" />
            <TechnologiesItem imgUrl="logo-React-192.png" />
            <TechnologiesItem imgUrl="logo-Swift-96.png" />
            <TechnologiesItem imgUrl="logo-typescript-96.png" />
            <TechnologiesItem imgUrl="Redux_Logo.png" />
        </div>
        </>
    );
}

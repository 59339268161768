type technologiesItem = {
    imgUrl: string;
}

export default function TechnologiesItem(props:technologiesItem) {
    return (
        <>
        <img className="technologies_item_img" alt="" src= {props.imgUrl} />
        </>
    );
}
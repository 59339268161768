type serviceItem = {
    name: string;
    imgUrl: string;
}

export default function DevServiceItem(props:serviceItem) {
    return (
        <>
        <div className="service_item">
            <img className="service_item_img" alt="" src= {props.imgUrl} />
            <div className="service_item_text"> 
                {props.name} 
            </div>
        </div>
        </>
    );
}
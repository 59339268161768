import ContentHeader from "./ContentHeader";
import TelephoneNumberHiden from "./TelephoneNumberHiden";

export default function ContactsPage(): JSX.Element {
    return (
        <>
        <div className="content_box">
            <ContentHeader textHearder={'Контакты'} />
            <div className="contact_conteiner">
                <div className="contact_item">
                    <img className="contact_item_image" alt="" src="telephone_number.svg" />
                    
                
                    <TelephoneNumberHiden telNum={"+7 918 46-33-009;"} 
                        telNumHiden={"+7 XXX XX-XX-XXX;"} 
                        className={"contact_item_text"} />
                    <TelephoneNumberHiden telNum={"+7 989 80-80-125."} 
                        telNumHiden={"+7 XXX XX-XX-XXX."} 
                        className={"contact_item_text"} />
                </div>
                <div className="contact_item">
                    <img className="contact_item_image" alt="" src="Frame_155.svg" />
                    <div className="contact_item_text">
                        <a href="mailto:admin@mitra123.pro" className="send_mail">admin@mitra123.pro</a></div>
                </div>
            </div>
        </div>
        </>
    );
}

/*
<div className="contact_item_text">+7 (918) 463-30-09; +7 (989) 808-01-25</div>
*/

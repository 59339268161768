import './App.css';
import MainMenu from './components/MainMenu';
import Footer from './components/Footer';
import Mission from './components/Mission';
import Logo from './components/Logo';
import Content from './components/Content';
import Feedback from './components/Feedback';

function App() {
  function changeFavicon(src: string) {
    var link = document.createElement('link'),
        oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-favicon';
    link.rel = 'shortcut icon';
    link.href = src;
    if (oldLink) {
     document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
   }
   
  /*changeFavicon("cloud.png");*/
  changeFavicon("mitra123pro_favicon.svg");
  document.title = "Митра Про 123"

  return (
    <>
    <div className='screen'>
      <div className='logo'>
        <Logo />
      </div>
      <div className='menu'>
        <MainMenu />
      </div>
      <div className='mission'>
        <Mission />
      </div>
      <div className='content'>
        <Content />
      </div>
      <div className='feedback'>
        <Feedback />
      </div>
      <div className='footer'>
        <Footer />
      </div>
    </div>
    </>
  );
}

export default App;

export default function Logo() {
    return (
        <>
            <img
                className="mobile-logo-black-icon"
                alt="МитраПро123"
                src="mobile-logo-black.png"
            />
        </>
    );
}


export default function Feedback(): JSX.Element {
  const handlerSendMessage = async (event: any): Promise<void> => {
    event.preventDefault()

    const request = {
      "text": event.target.contentInput.value,
      "name": event.target.nameInput.value,
      "from_site": "mipro123",
      "email": event.target.emailInput.value,
      };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request),
      };

/* "http://5.35.91.24:8000/api/messages/" */
    fetch('https://mitrapro.tech/api/messages/', requestOptions)
      .then(() => {
        event.target.contentInput.value = "";
      })
  }

  return (
    <>
    <form className="feedback_box" onSubmit={handlerSendMessage} >
      <h3 className="feedback_form_header">Отправьте вопрос или предложение</h3>
      
      <input
        className="feedback_form_field"
        placeholder="Ваше имя"
        required
        type="text"
        name="name" 
        maxLength={32}
        id="nameInput"
      />

      <input
        className="feedback_form_field"
        placeholder="Ваш e-mail"
        required
        type="text"
        name="email" 
        id="emailInput"
      />
        
      <textarea 
        name="text" 
        maxLength={128} 
        required
        className="feedback_form_field_text"
        placeholder="Ваш вопрос или предложение"
        rows={6}
        cols={16}
        id="contentInput"
      />

      <input 
        type="submit" 
        className="button_send"
        name="submit_btn" 
        value="Отправить" 
      />     
      
    </form>
    </>
  );
}

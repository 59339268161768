import { useState } from "react";
import ContentHeader from "./ContentHeader";
import styled from "styled-components";
import { link } from "fs";


export default function ProjectsPage() {
    const projectArray = [
        {
            serviceTitle: "Прототипирование.",
            taskTitle: "Сопряжение смартфона с микроконтроллером.",
            image: "Project1-L-monoCgrome.png",
            alt: "Смартфон&Микроконтроллер",
            text: [ "Плата NodeMCU, iPhone (iOS).",
                    "Передача данных через WiFi.", 
                    "Программа для NodeMCU реализована на С++", 
                    "Приложение iOS реализовано на Swift.", 
                    "Исполняемая среда эмулятор Xcode.",
                ],
                linkName: "",
                linkTitle: "",
            },
        {
            serviceTitle: "Прототипирование.",
            taskTitle: "Сопряжение электронных весов с микроконтроллером",
            image: "Project2-S-monoChrome.png",
            alt: "Весы&Микроконтроллер",
            text: [ "Электронные весы с RS-232, плата Arduino (Wemos D1), LCD-модуль.", 
                    "Протокол передачи данных CAS-M.",
                    "Программа для Arduino реализована на С++.",
            ],
            linkName: "",
            linkTitle: "",
        },
        {
            serviceTitle: "Разработка web-приложения.",
            taskTitle: "Этот сайт",
            image: "this_site.png",
            alt: "ЭтотСайт",
            text: [ "frontend реализован на React.", 
                    "backend реализован на Django в виде отдельного сервиса для сайтов группы компаний.",
            ],
            linkName: "",
            linkTitle: "",
        },
        {
            serviceTitle: "Разработка web-приложения.",
            taskTitle: "Сайт про изготовление книг",
            image: "mitra-print.png",
            alt: "mitra-print.ru",
            text: [ "frontend реализован на React.", 
            "backend реализован на Django в виде отдельного сервиса для сайтов группы компаний."
            ],
            linkName: "http://5.35.91.24:3010/",
            linkTitle: "mitra-print.ru",
        },
        {
            serviceTitle: "Разработка web-приложения.",
            taskTitle: "Портал petbreeder.pro",
            image: "Proj-petbreeder-pro.png",
            alt: "petbreeder.pro",
            text: [ "Маркетплейс домашних животных.",
                    "В процессе разработки.",
                    "frontend реализован на React.", 
                    "backend реализован на Django.",
            ],
            linkName: "https://petbreeder.pro",
            linkTitle: "petbreeder.pro",
        },

    ]

    const [curentIndex, setCurentIndex] = useState(0);
    const [nextIndex, setNextIndex] = useState(1);

    const listItems = projectArray[curentIndex].text.map((item) =>
        <p key={item}>{item}</p>);

    const handlerNextProject = () => {
        if (curentIndex == projectArray.length - 1) {
            setCurentIndex(0);
        } else {
            setCurentIndex(curentIndex + 1);
        }

        if (nextIndex == projectArray.length - 1) {
            setNextIndex(0);
        } else {
            setNextIndex(nextIndex + 1);
        }
    }
    return (
        <>
        <div className="content_box">
            <ContentHeader textHearder={'Наши проекты'} />
            <div className="project_conteiner">
                <img className="project_conteiner_content_main_image" 
                    alt={projectArray[curentIndex].alt} 
                    src={projectArray[curentIndex].image}
                />

                <div className="project_conteiner_content_main_text">
                    <h4 className="project_conteiner_content_main_text_header">
                        {projectArray[curentIndex].serviceTitle}
                    </h4>
                    <h3 className="project_conteiner_content_main_text_header">
                        {projectArray[curentIndex].taskTitle}
                    </h3>
                    <div className="project_conteiner_content_main_text_stye">
                        {listItems}
                    </div>
                    {
                        projectArray[curentIndex].linkTitle.length > 0 ?
                            <p>
                                <a href={projectArray[curentIndex].linkName} target="_blank">
                                    {projectArray[curentIndex].linkTitle}
                                </a>
                            </p> : <></>
                    }
                </div>
                <div className="project_conteiner_content_next_image_frame">
                    <div className="project_conteiner_content_empty"></div>
                    <button className="project_conteiner_content_next_image"
                        onClick={handlerNextProject}
                    >
                        <img className="button_image"
                            alt={projectArray[nextIndex].alt} 
                            src={projectArray[nextIndex].image}
                        />

                    </button>
                </div>                                           
            </div>
        </div>
        </>
    );
}

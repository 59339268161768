export default function ToDo() {
    return (
        <>
        <div className="to_do">
            <div className="to_do_item">
                <div className="our_command_header">
                    <h4>Выполняем:</h4>
                </div>
            
                <ul className="our_command_paragraphs">
                    <li className="our_command_paragraph_text">
                        проектирование
                    </li>
                    <li className="our_command_paragraph_text">
                        прототипирование
                    </li>
                    <li className="our_command_paragraph_text">
                        построение
                    </li>
                    <li className="our_command_paragraph_text">
                        внедрение
                    </li>
                    <li className="our_command_paragraph_text">
                        сопровождение
                    </li>
                </ul>
             </div>
        </div>
        </>
    );
}

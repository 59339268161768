export default function OurCommand() {
    return (
        <>
        <div className="our_command">
            <div className="our_command_item">
                <div className="our_command_header">
                    <h4>Наша команда с радостью возьмётся за построение:</h4>
                </div>
            
                <ul className="our_command_paragraphs">
                    <li className="our_command_paragraph_text">
                        информационных систем
                    </li>
                    <li className="our_command_paragraph_text">
                        микропроцессорных систем
                    </li>
                    <li className="our_command_paragraph_text">
                        интернет-порталов
                    </li>
                    <li className="our_command_paragraph_text">
                        сайтов
                    </li>
                    <li className="our_command_paragraph_text">
                        интернет-магазинов
                    </li>
                    <li className="our_command_paragraph_text">
                        систем управления и контроля функционирования объектов
                    </li>
                </ul>
             </div>
        </div>
        </>
    );
}

import DevServices from "./DevServises";
import DevStages from "./DevStages";

export default function ServicesPage() {

    return (
        <>
        <div className="content_box">
            <DevServices />
            <div className="dev_serv" />
            <DevStages />
        </div>
        </>
    );
}

import { NavLink } from "react-router-dom";

export default function MainMenu() {
    return (
        <>
        <div className="menu_box">
            <NavLink className='menu_item' to="/">Главная</NavLink>
            <NavLink className='menu_item' to="/services">Услуги</NavLink>
            <NavLink className='menu_item' to="/technologies">Технологии</NavLink>
            <NavLink className='menu_item' to="/projects">Проекты</NavLink>
            <NavLink className='menu_item' to="/contacts">Контакты</NavLink>
        </div>
        </>
    );
}

import ContentHeader from "./ContentHeader";
import DevServiceItem from "./DevServiceItem";

export default function DevServices() {
    return (
        <>
            <ContentHeader textHearder={'Наши услуги'} />
            <DevServiceItem name={"Разработаем web-приложение"} imgUrl={"data_6522816.svg"}/>
            <DevServiceItem name={"Составим техническое задание"} imgUrl={"wireframe_6868973.svg"}/>
            <DevServiceItem name={"Разработаем дизайн"} imgUrl={"illustration_3939992.svg"}/>
            <DevServiceItem name={"Выполним программирование"} imgUrl={"code_optimization.svg"}/>
            <DevServiceItem name={"Обучим персонал"} imgUrl={"select_1924454.svg"}/>
            <DevServiceItem name={"Внедрим web-приложение"} imgUrl={"ux-design_7889802.svg"}/>
            <DevServiceItem name={"Зарегистрируем домен"} imgUrl={"internet_2721688.svg"}/>
            <DevServiceItem name={"Выполним техническое сопровождение"} imgUrl={"website_977597.svg"}/>
            <DevServiceItem name={"Развернем приложение на сервере"} imgUrl={"app_deploy.svg"}/>
            <DevServiceItem name={"Проведем тестирование"} imgUrl={"rinse_3206217.svg"}/>
            <DevServiceItem name={"Решим текущие задачи и проблемы"} imgUrl={"browser_858013.svg"}/>
            <DevServiceItem name={"Выполним резервное копирование данных"} imgUrl={"coding_2085105.svg"}/>
            <DevServiceItem name={"Доработаем приложение под новые задачи"} imgUrl={"communications_1568578.svg"}/>
        
        </>
    );
}


import ContentHeader from "./ContentHeader";
import OurCommand from "./OurCommand";
import ToDo from "./ToDo";

export default function HomePage() {
    return (
        <>  
        <ContentHeader textHearder={'О нас'} /> 
        <div className="content_column">
            <div className="content_column_container_left">
                <OurCommand />
            </div>
        </div>
        <div className="content_column">
            <div className="content_column_container_left">
                <ToDo />
            </div>
        </div>
        </>
    );
}